/* Generic style file (30.12.2022, Timo Böhme) */
/* Import by using following line: */
/* import styles from ../../../assets/styles.css; */

.hoverAnimation :hover {
  transition: all 150ms ease;
  z-index: 99;
  transform: scale(1.006);
}

.cardAnimation {
  transition: all 150ms ease;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 4px 2px;
}

.cardContainer {
  box-shadow: rgba(0, 0, 0, 0.4) 1px 1px 4px 1px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.imageFrame {
  height: 50px;
  /* Can be anything */
  width: 50px;
  /* Can be anything */
  position: relative;
}

.smallLogo {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.cardStyle {
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cardFoo {
  background-color: #fff;
}

.asideImage {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}